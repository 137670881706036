import React, { useCallback, useEffect, useState } from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { Stack, Typography, Link, Box, Tooltip } from "@mui/material";
import { convertUTCDateTimeToLocalDate, convertUTCDateTimeToLocalTime, mapMedicalDocumentsStatusToLabel, mapFileEntityDataToStatus } from "../common";
import { isDemandOrCoverLetter } from "../common-document";
import { useSelector, useDispatch } from "react-redux";
import { setPdfPanelOpen } from "../redux/slices/demandDomSlice";
import moment from "moment";
import StatusChip from "../CommonComponents/Chips/StatusChip";
import { useGetAllFilesByDocumentIdQuery } from "../services/documentFiles/documentFilesApi";
import { useGetDocumentDataQuery } from "../services/documentData/documentDataApi";
import { isPrecedentUser } from "../common-roles";
import { DOCUMENT_UPLOADED } from "../common-demand";
import { NO_TEMPLATE } from "../DemandComponents/DemandForms/FormHelpers";

import { useParams } from "react-router-dom";
import { InsertPageBreakOutlined } from "@mui/icons-material";

const MedicalDocumentsTable = ({ setCurrentPage, setCurrentPageTrigger, currentPageTrigger, currentPage, setCurrentBoundingBoxes, currentBoundingBoxes, userData, hasMedicalTreatments }) => {
    const { documentId } = useParams();
    const dispatch = useDispatch();


    const { data: documentData = {}, isLoading: documentDataLoading } =
        useGetDocumentDataQuery({ documentId: documentId, pollPdfStatus: false }, { skip: !documentId });

    const demandTemplateId = documentData?.metadata?.demandTemplateId;
    const noTemplatedId = !demandTemplateId || (demandTemplateId === NO_TEMPLATE);
    const noCoverLetter = !documentData?.metadata?.demandHasCoverLetter;

    const documentStatus = documentData?.metadata?.documentStatus || '';
    
    const [awaitingNavigation, setAwaitingNavigation] = useState(false);
    const [shouldPoll, setShouldPoll] = useState(false);

    const [columnWidths, setColumnWidths] = useState({}); // State to track column widths
    const apiRef = useGridApiRef(); // Initialize apiRef to access DataGridPro's API
    const getRowHeight = useCallback(() => 'auto', []);

    const handleResizeStop = useCallback((params) => {
        setColumnWidths((prevWidths) => ({
            ...prevWidths,
            [params.colDef.field]: params.colDef.width,
        }));

        // Force a row height recalculation after resizing
        apiRef.current.resetRowHeights();
    }, [apiRef]);

    //good example of using the useGetAllFilesByDocumentIdQuery hook with conditional polling
    const { data: documentFiles = [], isLoading: documentFilesLoading } = useGetAllFilesByDocumentIdQuery(documentId, {
        pollingInterval: shouldPoll ? 3000 : 0,
        refetchOnMountOrArgChange: true,
    });

    const rows = documentFiles.map((file, index) => {
        return {
            id: index + 1,
            name: file?.fileEntityData?.fileName || '',
            alias: file?.fileEntityData?.aliasFileName || '',
            detectedType: file?.fileEntityData?.detectedType || '',
            extraction: mapFileEntityDataToStatus(file.fileEntityData, hasMedicalTreatments, isPrecedentUser(userData), documentStatus !== DOCUMENT_UPLOADED && file.fileEntityData?.skipAIProcessing),
            startPage: file?.fileEntityData?.startPage || 0,
            endPage: file?.fileEntityData?.endPage || 0,
            createdDate: file?.createdDate ? moment.utc(file?.createdDate) : '',
            hasDivider: Boolean(file?.fileEntityData?.dividerPageId),
            aiProcessingSkippedBy: file?.fileEntityData?.aiProcessingSkippedBy,
        }
    });

    const filesHaveStartPages = rows.every((row) => Boolean(row?.startPage));
    const isDemandOrCoverLetterLength = rows.filter((row) => isDemandOrCoverLetter(row.detectedType)).length;
    // noTemplateId and noCoverLetter each count as 1. Check to see that we've fetched the correct number of files
    const hasExpectedFiles = isDemandOrCoverLetterLength >= (noTemplatedId ? 0: 1) + (noCoverLetter ? 0 : 1);

    // poll if files don't have start pages or if there are not 'expected files'
    useEffect(() => {
        (!filesHaveStartPages || !hasExpectedFiles) ? setShouldPoll(true) : setShouldPoll(false);
    }, [filesHaveStartPages, hasExpectedFiles]);

    const { pdfPanelOpen, pdfInstance } = useSelector(state => state.DemandDom);
    const { demandS3RegenerationLoadingData } = useSelector((state) => state.PdfCustomSlice);
    const isRegenLoading = Boolean(demandS3RegenerationLoadingData[documentId]);

    useEffect(() => {
        if (awaitingNavigation && pdfPanelOpen && pdfInstance) {
            setTimeout(() => {
                setAwaitingNavigation(false);
                setCurrentPage(currentPage);
                setCurrentPageTrigger(currentPageTrigger + 1);
            }, 3000);
        }
    }, [pdfPanelOpen, pdfInstance]);

    const getExtractionStatusTooltip = (extractionStatus, aiProcessingSkippedBy = '') => {
        switch(extractionStatus) {
            case 'Upload failed':
                return 'This document could not be processed. Please re-upload the document and confirm the file type is .pdf, .doc, .docx, .jpg, .jpeg, .png.  The document cannot be password-protected.';
            case 'Bypassed AI processing':
                return `Bypassed by ${aiProcessingSkippedBy}`;    
            default:
                return '';
        }  
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: columnWidths['name'] || 375,
            minWidth: 150,
            renderCell: (params) => {
                const aliasDifferentThanName = params.row.alias && (params.row.alias !== params.row.name)

                const handleNavigate = () => {
                    setCurrentPage(params.row.startPage - 1);
                    setCurrentPageTrigger(currentPageTrigger + 1);
                    currentBoundingBoxes?.length > 0 && setCurrentBoundingBoxes([]);
                    if (!pdfPanelOpen) {
                        setAwaitingNavigation(true);
                        dispatch(setPdfPanelOpen(true));
                    }
                };
                return (
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}

                    >
                        {params.row.hasDivider &&
                            <Stack
                                sx={{
                                    pr: 2
                                }}
                            >
                                <Tooltip
                                    title="Divider page before this document"
                                    placement="right"
                                    arrow
                                >
                                    <InsertPageBreakOutlined
                                        color="action"
                                    />
                                </Tooltip>
                            </Stack>
                        }
                        <Stack>
                            <Typography
                                component={params?.row?.startPage ? Link : 'span'}
                                onClick={params?.row?.startPage ? handleNavigate : null}
                                variant="tableP1"
                                color={params?.row?.startPage ? 'secondary' : 'inherit'}
                                sx={{
                                    textDecoration: 'none',
                                    textAlign: 'left',
                                    cursor: params?.row?.startPage ? 'pointer' : 'default',
                                }}
                            >
                                {aliasDifferentThanName ? params.row.alias : params.row.name}
                            </Typography>
                            {aliasDifferentThanName &&
                                <Typography variant="tableP2">
                                    {params.row.name}
                                </Typography>
                            }
                        </Stack>

                    </Stack>
                );
            },
        },
        {
            field: "extraction",
            headerName: "Status",
            width: columnWidths['extraction'] || 250,
            minWidth: 125,
            valueGetter: (params) => mapMedicalDocumentsStatusToLabel(params.row.extraction),
            renderCell: (params) => (
                params.row.extraction === 'demandOrCoverLetter' ?
                    <Typography
                        variant="tableP2"
                        sx={{
                            pl: 2
                        }}
                    >
                        -
                    </Typography>
                    :
                    <Tooltip
                        title={getExtractionStatusTooltip(params.row.extraction, params.row.aiProcessingSkippedBy)}
                        placement='right'
                        arrow
                    >
                        <div>
                            <StatusChip
                                type='file'
                                showInfoIcon={['MedicalsInProgress', 'AI processing', 'Bypassed AI processing'].includes(params.row.extraction)}
                                documentStatus={params.row.extraction}
                                showEditOffIcon={false}
                            />
                        </div>
                    </Tooltip>

            ),
        },
        {
            field: "createdDate",
            headerName: "Uploaded",
            width: columnWidths['createdDate'] || 150,
            minWidth: 100,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => {
                const { createdDate } = params.row;
                return (
                    <Stack>
                        <Typography variant="tableP1">
                            {createdDate ? convertUTCDateTimeToLocalDate(createdDate) : ''}
                        </Typography>
                        <Typography variant="tableP2">
                            {createdDate ? convertUTCDateTimeToLocalTime(createdDate) : ''}
                        </Typography>
                    </Stack>
                );
            },
        },
    ];

    const columnsWithOptions = columns.map((column) => {
        return {
            ...column,
            resizable: true,
            editable: false,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            disableColumnSelector: true,
        }
    });

    const isLoading = documentDataLoading || documentFilesLoading || isRegenLoading || !filesHaveStartPages || !hasExpectedFiles;

    return (
        <Box sx={{ flex: 1, position: 'relative' }}>
            <Box sx={{ position: 'absolute', inset: 0 }}>
                <DataGridPro
                    loading={isLoading}
                    rows={isLoading ? [] : rows}
                    columns={columnsWithOptions}
                    disableRowSelectionOnClick
                    disableColumnReorder
                    getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'}
                    apiRef={apiRef}
                    onColumnWidthChange={handleResizeStop} // Capture column resize
                    getRowHeight={getRowHeight}
                    sx={{
                        "& .MuiDataGrid-cell": {
                            minHeight: 43,
                        }
                    }}
                />
            </Box>
        </Box>
    );
}

export default MedicalDocumentsTable;